import React, { lazy, Suspense } from 'react'
import { RoleDetailsProps } from './RoleDetails'

const LazyRoleDetails = lazy(() => import('./RoleDetails'))

const RoleDetails = (props: RoleDetailsProps) => (
    <Suspense fallback={null}>
        <LazyRoleDetails {...props} />
    </Suspense>
)

export default RoleDetails
