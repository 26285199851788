import React, { lazy, Suspense } from 'react'
import { FeedProps } from './Feed'
const LazyFeed = lazy(() => import('./Feed'))

const Feed = (props: FeedProps) => (
    <Suspense fallback={null}>
        <LazyFeed {...props} />
    </Suspense>
)

export default Feed
