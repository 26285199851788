import React, { lazy, Suspense } from 'react'
import { Wv16GridProps, Wv16PlaylistProps } from './Wv16'
const LazyWv16Grid = lazy(() => import('./Wv16').then(m => ({ default: m.Wv16Grid })))
const LazyWv16Playlist = lazy(() => import('./Wv16').then(m => ({ default: m.Wv16Playlist })))

export const Wv16Grid = (props: Wv16GridProps) => (
    <Suspense fallback={null}>
        <LazyWv16Grid {...props} />
    </Suspense>
)
export const Wv16Playlist = (props: Wv16PlaylistProps) => (
    <Suspense fallback={null}>
        <LazyWv16Playlist {...props} />
    </Suspense>
)
