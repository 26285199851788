import React, { lazy, Suspense } from 'react'
import { CheckMyFitProps } from './CheckMyFit'
const LazyCheckMyFit = lazy(() => import('./CheckMyFit'))

const CheckMyFit = (props: CheckMyFitProps) => (
    <Suspense fallback={null}>
        <LazyCheckMyFit {...props} />
    </Suspense>
)

export default CheckMyFit
