import React, { lazy, Suspense } from 'react'

const LazyJobPage = lazy(() => import('./JobPage'))

const JobPage = (props: {}) => (
    <Suspense fallback={null}>
        <LazyJobPage {...props} />
    </Suspense>
)

export default JobPage
