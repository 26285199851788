import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from 'locales/en/translation.json'

// import ChainedBackend from 'i18next-chained-backend';
// import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend'

// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18next
    .use(
        resourcesToBackend((lng, ns, clb) => {
            if (lng === 'en' && ns === 'translations') {
                clb(null, en) // re import the main one and preload it at the top of the file;
            }
            import(`./locales/${lng}/${ns}.json`).then(resources =>
                clb(null, resources),
            )
            // .catch(clb)
            clb(null, en)
        }),
    )
    .use(initReactI18next)
    .init({
        fallbackLng: (code?: string) => {
            if (!code) {
                return ['en']
            }
            code.slice(0, 2) // if specific locale-code doesn't match, try broader language code
            return [code.slice(0, 2), 'en']
        },
        debug: import.meta.env.DEV,
        react: { bindI18n: 'languageChanged', bindI18nStore: 'added' },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

if (import.meta.env.DEV) {
    import('translation-check').then(({ showTranslations }) => {
        // @ts-ignore
        window.showTranslations = () => {
            showTranslations(i18next)
        }
    })
}
export default i18next
