import React, { lazy, Suspense } from 'react'
import { InactiveAppProps } from './InactiveApp'

const LazyInactiveApp = lazy(() => import('./InactiveApp'))

const InactiveApp = (props: InactiveAppProps) => (
    <Suspense fallback={null}>
        <LazyInactiveApp {...props} />
    </Suspense>
)

export default InactiveApp
