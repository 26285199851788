import React, { lazy, Suspense } from 'react'
import { ScheduleRoleRepProps } from './ScheduleRoleRep'
const LazyScheduleRoleRep = lazy(() => import('./ScheduleRoleRep'))

const ScheduleRoleRep = (props: ScheduleRoleRepProps) => (
    <Suspense fallback={null}>
        <LazyScheduleRoleRep {...props} />
    </Suspense>
)

export default ScheduleRoleRep
