import React, { lazy, Suspense } from 'react'
import { QuickLinksProps } from './QuickLinks'

const LazyQuickLinks = lazy(() => import('./QuickLinks'))

const QuickLinks = (props: QuickLinksProps) => (
    <Suspense fallback={null}>
        <LazyQuickLinks {...props} />
    </Suspense>
)

export default QuickLinks
