import { useState, useEffect, useMemo } from 'react'

import {
    Routes,
    Route,
    useParams,
    useLocation,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom'
import { Spin } from 'antd'
import {
    logEvent,
    getNewSessionId,
    getNewSessionExpirationTime,
    useManifestType,
    PersonalizationManifest,
    useVideoManifests,
    useSurveyDetails,
    useRoleSearchInfo,
    useCandidateStatus,
    useSingleVideoManifest,
    DeactivationInfo,
    usePostMessageOverride,
    useDomainInfo,
    RoleSearchInfo,
} from 'services/s3_services'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import LandingPage from 'pages/LandingPage/LandingPage.lazy'
import CheckMyFit from 'pages/CheckMyFit/CheckMyFit.lazy'
// import ScheduleInterview from 'pages/ScheduleInterview/ScheduleInterview.lazy'
import NotInterested from 'pages/NotInterested/NotInterested.lazy'
import NotInterestedAlreadyTaken from 'pages/NotInterested/components/NotInterestedAlreadyTaken/NotInterestedAlreadyTaken.lazy'
// import Referrals from 'pages/Referrals/Referrals.lazy'
import RoleDetails from 'pages/RoleDetails/RoleDetails.lazy'
// import { Base } from './templates/Base'
import CareersPage from 'pages/CareersPage/CareersPage.lazy'
import JobPage from 'pages/CareersPage/pages/JobPage/JobPage.lazy'
import QuickLinks from 'pages/QuickLinks/QuickLinks.lazy'
import CheckMyFitAlreadyTaken from 'pages/CheckMyFitAlreadyTaken/CheckMyFitAlreadyTaken.lazy'
import ScheduleRoleRep from 'pages/ScheduleRoleRep/ScheduleRoleRep.lazy'
import Feed from 'pages/Feed/Feed.lazy'
import FeedIntro from 'pages/FeedIntro/FeedIntro.lazy'
import SingleVideo from 'pages/SingleVideo/SingleVideo.lazy'

import InactiveApp from 'pages/InactiveApp/InactiveApp.lazy'
import { useTranslation } from 'react-i18next'
import { Wv16Grid, Wv16Playlist } from 'pages/LandingPage/components/MenuAndVideoPlayer/Wv16.lazy'
declare global {
    interface Window {
        rolesearchToken: string
        projectToken?: string
        feedToken: string
        videoToken: string
        candidateToken: string
        sessionIdentifier: string
        preview: boolean
        lastModified?: string
        source?: string
        personalizationToken?: string
        sessionExpirationTime: Date
        disableLogging?: boolean // this is meant for use when we embed channels/pages in the client app, in builders
        personalizationManifest?: PersonalizationManifest // maybe someday we'll break down and just add rq
    }
}

// https://www.figma.com/file/sGyJEVouymY6PE5DzJzyuA/Wednesday-Talent---Candidate-App?node-id=150%3A3421
// const CheckMyFitAlreadyTaken = () => <div>Thanks for submitting!</div>

interface CandidateAppParams extends Record<string, string | undefined> {
    itemToken: string
    candidateToken: string
}

const CandidateApp = () => {
    const { itemToken = '', candidateToken = '', projectToken } = useParams<CandidateAppParams>()
    const rolesearchToken =
        itemToken.startsWith('RS') || itemToken.startsWith('FC') ? itemToken : ''
    const videoToken = itemToken.startsWith('RRRV') ? itemToken : ''

    const searchParams = new URLSearchParams(window.location.search)
    const preview = !!searchParams.get('preview')
    const cfId = searchParams.get('cfId') // the cloudfront request id is optionally sent from the widget so one instance of the widget loading counts as a single session even though we reload the app
    const encodedParam = searchParams.get('wtc')
    let source = searchParams.get('s') || undefined
    if (encodedParam) {
        try {
            source = JSON.parse(atob(encodedParam))?.s
        } catch {}
    }
    const isWidget = searchParams.get('iframeParentLocation')
    const widgetIsExpanded = searchParams.get('widgetExpanded')
    const personalizationToken = searchParams.get('p')
    const disableLogging = !!searchParams.get('disableLogging')
    window.rolesearchToken = rolesearchToken
    window.projectToken = projectToken
    window.videoToken = videoToken
    window.disableLogging = disableLogging

    window.candidateToken = candidateToken || ''
    window.preview = preview
    window.source = source
    window.personalizationToken = personalizationToken || undefined
    if (cfId) {
        window.sessionIdentifier = cfId
    } else {
        window.sessionIdentifier = getNewSessionId()
    }
    window.sessionExpirationTime = getNewSessionExpirationTime()

    const [surveyTakenStatus, setSurveyTakenStatus] = useState<boolean>(false)

    const { data: videoTopics } = useVideoManifests([rolesearchToken])[0]
    const { data: surveyDetails } = useSurveyDetails(rolesearchToken)
    const { data: roleSearchInfo } = useRoleSearchInfo(rolesearchToken)
    const { data: candidateStatus } = useCandidateStatus(
        rolesearchToken,
        candidateToken,
        projectToken,
    )
    const { data: singleVideoInfo } = useSingleVideoManifest(videoToken)

    const [candidateAppDeactivationInfo, setCandidateAppDeactivationInfo] = useState(
        {} as DeactivationInfo,
    )
    useEffect(() => {
        if (surveyDetails?.deactivationInfo !== undefined) {
            setCandidateAppDeactivationInfo(surveyDetails.deactivationInfo)
        }
    }, [surveyDetails])

    useEffect(() => {
        if (candidateStatus !== undefined && candidateToken) {
            setSurveyTakenStatus(candidateStatus === 'submitted' || candidateStatus === 'pending')
        }
    })

    const { i18n, t } = useTranslation(undefined, { useSuspense: false })

    useEffect(() => {
        // delayed to avoid making this request while there's likely other more important
        // requests outstanding to avoid wasting connections
        // really importance: low would be ideal if priority-hints were real
        setTimeout(() => {
            if (isWidget && widgetIsExpanded) {
                logEvent({ event_category: 'WIDGET_EXPANDED' })
            } else {
                if (!(personalizationToken && !isWidget)) {
                    // if you are loading a personalization, let the iframed channel send events, not the parent container [we dont want dupe VISIT events - which one we keep is abitrary, this seemed easier to catch]
                    logEvent({ event_category: 'VISIT_APP' })
                }
            }
        }, 1000)
    }, [])

    return (
        <>
            {/* <Router basename={baseName}> */}
            {candidateAppDeactivationInfo?.deactivated ? (
                <Routes>
                    <Route
                        path="/"
                        element={<InactiveApp deactivationInfo={candidateAppDeactivationInfo} />}
                    />
                </Routes>
            ) : (
                <>
                    {window.preview && !isWidget ? (
                        <div
                            title={window?.lastModified}
                            style={{
                                zIndex: 100000,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                backgroundColor: 'white',
                                textAlign: 'center',
                                border: '1px solid black',
                            }}
                        >
                            {t(
                                'preview-warning',
                                'This is a preview! Submissions will not be recorded',
                            )}
                        </div>
                    ) : null}
                    <Routes>
                        <Route
                            path="/check-my-fit"
                            element={
                                surveyTakenStatus ? (
                                    <CheckMyFitAlreadyTaken
                                        totalNumSteps={
                                            (surveyDetails?.qualificationQuestions ?? []).length +
                                            (candidateToken ? 0 : 1)
                                        }
                                    />
                                ) : (
                                    <CheckMyFit
                                        rolesearchToken={rolesearchToken}
                                        projectToken={projectToken}
                                        candidateToken={candidateToken}
                                        setSurveyTakenStatus={setSurveyTakenStatus}
                                        surveyDetails={surveyDetails}
                                    />
                                )
                            }
                        />

                        <Route
                            path="/not-interested"
                            element={
                                surveyTakenStatus ? (
                                    <NotInterestedAlreadyTaken />
                                ) : (
                                    <NotInterested
                                        rolesearchToken={rolesearchToken}
                                        candidateToken={candidateToken}
                                        setSurveyTakenStatus={setSurveyTakenStatus}
                                        surveyDetails={surveyDetails}
                                    />
                                )
                            }
                        />

                        <Route
                            path="/role-details"
                            element={
                                roleSearchInfo ? (
                                    <RoleDetails
                                        rolesearchToken={rolesearchToken}
                                        candidateToken={candidateToken}
                                        roleSearchInfo={roleSearchInfo}
                                    />
                                ) : null
                            }
                        />

                        <Route
                            path="/*"
                            element={
                                (videoTopics?.length ?? 0) > 0 && rolesearchToken ? (
                                    <LandingPage
                                        rolesearchToken={rolesearchToken}
                                        videoTopics={videoTopics ?? []}
                                        surveyTakenStatus={surveyTakenStatus}
                                        roleSearchInfo={roleSearchInfo}
                                        surveyDetails={surveyDetails}
                                    />
                                ) : videoToken ? (
                                    <SingleVideo
                                        videoToken={videoToken}
                                        singleVideoInfo={singleVideoInfo}
                                    />
                                ) : null
                            }
                        />
                    </Routes>
                </>
            )}
        </>
    )
}

const FourOhFour = () => {
    const domainInfo = useDomainInfo()
    if (domainInfo.data?.companySlug) {
        return <CareersPage />
    }
    // eslint-disable-next-line i18next/no-literal-string
    return <div>404 page</div>
}

const PublishedContent = ({ isFeedIntro }: { isFeedIntro?: boolean }) => {
    const { itemToken } = useParams()
    const { search } = useLocation()
    const searchParams = useMemo(() => new URLSearchParams(search), [search])
    const preview = !!searchParams.get('preview')
    window.preview = preview
    const { data: manifestType } = useManifestType(itemToken, preview)
    const { data: roleSearchInfo } = useRoleSearchInfo(itemToken)
    const wv16 = (searchParams.get('wv16') as RoleSearchInfo['wv16']) || roleSearchInfo?.wv16
    const { data: surveyDetails } = useSurveyDetails(itemToken ?? '')
    usePostMessageOverride()

    if (manifestType === undefined) {
        return <Spin />
    }
    if (surveyDetails?.deactivationInfo?.deactivated) {
        return <InactiveApp deactivationInfo={surveyDetails.deactivationInfo} />
    }

    switch (wv16) {
        case 'playlist':
            return <Wv16Playlist />
        case 'grid':
            return <Wv16Grid />
        case 'grid-playlist':
            return <Wv16Grid playlistPlayer />
    }
    if (isFeedIntro) {
        return <FeedIntro />
    } else if (manifestType === 'page') {
        return <Feed />
    } else if (manifestType === 'playlist') {
        return <CandidateApp />
    } else {
        return <FourOhFour />
    }
}

const FeedDetailWrap = () => {
    useEffect(() => {
        // I'm only a little bit afraid that some funky UA won't do something reasonable if we just made it transparent all the time in the base index.html body
        // this particular route is a little more sensitive to not being transparented early enough
        const origColor = document.body.style.backgroundColor
        document.body.style.backgroundColor = '#fff0'
        return () => {
            document.body.style.backgroundColor = origColor
        }
    }, [])
    return <PublishedContent />
}
const router = createBrowserRouter([
    ...createRoutesFromElements(
        <>
            <Route
                path="/careers/:companyName/:job_id"
                element={<JobPage />}
            />
            <Route
                path="/careers/:companyName"
                element={<CareersPage />}
            />
            <Route
                path="/quick-links/:companyName"
                element={<QuickLinks />}
            />
            <Route
                path="/feed/:itemToken/*"
                element={<PublishedContent isFeedIntro={false} />}
            />
            <Route
                path="/feed/:itemToken/detail/:detailToken"
                element={<FeedDetailWrap />}
            />
            <Route
                path="/feed_intro/:itemToken/"
                element={<PublishedContent isFeedIntro={true} />}
            />
            <Route
                path="/schedule/:roleRepToken"
                element={<ScheduleRoleRep />}
            />
            <Route
                path="/:itemToken/*"
                element={<PublishedContent isFeedIntro={false} />}
            />
            {window.location.pathname.startsWith('/PRJ_') ? (
                <Route
                    path="/:projectToken/:itemToken/*"
                    element={<PublishedContent isFeedIntro={false} />}
                />
            ) : null}
            <Route
                path="/"
                element={<FourOhFour />}
            />
        </>,
    ),
])
function App() {
    const queryClient = useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: 60000,
                    },
                },
            }),
        [],
    )
    const [showDevtools, setShowDevtools] = useState(false)
    useEffect(() => {
        //@ts-ignore
        window.toggleDevTools = () => setShowDevtools(o => !o)
    }, [])
    return (
        <QueryClientProvider client={queryClient}>
            {showDevtools ? <ReactQueryDevtools /> : null}
            <RouterProvider router={router} />
        </QueryClientProvider>
    )
}

export default App
