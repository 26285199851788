import React, { lazy, Suspense } from 'react'
import { SingleVideoProps } from './SingleVideo'
const LazySingleVideo = lazy(() => import('./SingleVideo'))

const SingleVideo = (props: { children?: React.ReactNode } & SingleVideoProps) => (
    <Suspense fallback={null}>
        <LazySingleVideo {...props} />
    </Suspense>
)

export default SingleVideo
