import React, { lazy, Suspense } from 'react'
import { CheckMyFitAlreadyTakenProps } from './CheckMyFitAlreadyTaken'

const LazyCheckMyFitAlreadyTaken = lazy(() => import('./CheckMyFitAlreadyTaken'))

const CheckMyFitAlreadyTaken = (props: CheckMyFitAlreadyTakenProps) => (
    <Suspense fallback={null}>
        <LazyCheckMyFitAlreadyTaken {...props} />
    </Suspense>
)

export default CheckMyFitAlreadyTaken
