import React, { lazy, Suspense } from 'react'

const LazyNotInterestedAlreadyTaken = lazy(() => import('./NotInterestedAlreadyTaken'))

const NotInterestedAlreadyTaken = () => (
    <Suspense fallback={null}>
        <LazyNotInterestedAlreadyTaken />
    </Suspense>
)

export default NotInterestedAlreadyTaken
