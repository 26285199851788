import React, { lazy, Suspense } from 'react'
import { NotInterestedProps } from './NotInterested'

const LazyNotInterested = lazy(() => import('./NotInterested'))

const NotInterested = (props: NotInterestedProps) => (
    <Suspense fallback={null}>
        <LazyNotInterested {...props} />
    </Suspense>
)

export default NotInterested
