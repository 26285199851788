import axios from 'axios'
import { useQuery, useQueries, useQueryClient, QueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState, CSSProperties } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import React from 'react'
export const storageDomain =
    import.meta.env.VITE_STORAGE_DOMAIN || 'https://wt-dev-media.s3.amazonaws.com'

const CABDomain = import.meta.env.VITE_CAB_DOMAIN || ''
const clientUrl1 = import.meta.env.VITE_CLIENT_URI
const clientUrl2 = import.meta.env.VITE_ALT_CLIENT_URI
export interface VideoDetails {
    condensedLabel?: string
    url: string // without extension
    posterUrl?: string
    label: string
    prompt?: string
    token?: string
    respondent: { name?: string; title?: string }
    topic: string
    fileName: string
    audioOnly: boolean

    coverText: string
    labelLineOne: string
    labelLineTwo: string

    objectFit?: 'contain' | 'cover'
    scale?: number
    translate?: [number, number]
    type?: 'video'
    duration?: number
}

interface RawVideoDetails {
    fileName: string
    posterFileName?: string
    posterUrl?: string
    label: string
    respondent?: { name: string; title: string }
    topic: string
    audioOnly?: boolean

    coverText?: string
    labelLineOne?: string
    labelLineTwo?: string

    objectFit?: 'contain' | 'cover'
    type?: 'video'
    url?: string
}
interface RawTopicGroup {
    videos: (RawVideoDetails | RawFeedChannelDetails)[] // TODO: obv videos is now badly named, but keeping it for backwards compat for now
    topic: string
    introDuration?: number
    intro_video_url?: string
    key?: string
}

export interface TopicGroup {
    introDuration?: number
    videos: (VideoDetails | FeedChannelDetails)[]
    topic: string
    shortName?: string
    respondent?: Contributor
    key: string
}

export interface Contributor {
    name?: string
    title?: string
    additionalTitles?: string[]
    posterUrl?: string
    videoUrl?: string
    videoOptions?: {
        scale?: number
        translate?: [number, number]
    }
}
interface VideoManifest {
    topics: RawTopicGroup[]
}
export type SurveyResults = Record<string, string>

type RawNameOverrideType =
    | string
    | {
          coverText?: string
          labelLineOne?: string
          labelLineTwo?: string
      }
interface RawFeedVideoDetails extends RawVideoDetails {
    respondentOtherChannels: { name: string; link: string }[]
    prompt: string
    tags: string[]
    fragment?: string
    type: 'video'
    posterFileName?: string
    nameOverride?: RawNameOverrideType
    duration?: number
}
export interface FeedVideoDetails extends Omit<VideoDetails, 'label'> {
    respondentOtherChannels: { name: string; link: string }[]
    prompt: string
    tags: string[]
    type: 'video'
    posterUrl: string
    nameOverride?: string
    key: string
    duration?: number
}

export type FeedStyle = 'feed' | 'grid' | 'single' | 'flex'
export type FeedFilterOption = 'topic' | 'tag' | 'title'

interface RawFeedChannelDetails {
    url: string
    type: 'channel' | 'page'
    name: string
    poster_url: string
    nameOverride?: RawNameOverrideType
    topics?: string[]
    token?: string
}
export interface FeedChannelDetails extends Omit<RawFeedChannelDetails, 'nameOverride'> {
    key: string
    posterUrl?: string
    nameOverride?: string
}
interface RawFeedSection {
    name: string
    items: (RawFeedVideoDetails | RawFeedChannelDetails)[]
    type: 'content' | 'fullWidth'
}
export interface FeedSection {
    name: string
    items: (FeedVideoDetails | FeedChannelDetails)[]
    type: 'content' | 'fullWidth'
    key: string
}
interface RawFeedManifest {
    videos: RawFeedVideoDetails[]
    featuredLinks: { name: string; link: string }[]
    companyName: string
    name?: string
    description?: string
    logo_url: string | null
    banner_url?: string
    feedBackgroundColor?: string
    accentColor?: string
    v2?: boolean
    v4?: boolean
    options: {
        style?: FeedStyle
        allowedFilterCategories?: FeedFilterOption[]
        allowSearch?: boolean
        showTOC?: boolean
        externalCTA?: { url: string; text: string }
        page_config?: {
            ordered_sections: RawFeedSection[]
            v16?: 'playlist' | 'grid' | 'grid-playlist'
        }
    }
}

export interface LogoBackdropConfig {
    color?: string
    blur?: number
}
export interface FeedManifest {
    videos: FeedVideoDetails[]
    name: string
    description?: string
    featuredLinks: { name: string; link: string }[]
    companyName: string
    logo_url: string
    banner_url: string
    feedBackgroundColor: string
    feedAccentColor: string
    v2: boolean
    v4: boolean
    options: {
        style: FeedStyle
        showTOC?: boolean
        allowedFilterCategories: FeedFilterOption[]
        allowSearch: boolean
        externalCTA?: { url: string; text: string }
        page_config?: { ordered_sections: FeedSection[] }
        logoBackdropConfig?: LogoBackdropConfig
        isReel?: boolean
    }
}

interface rawSingleVideoManifest {
    video: string
    fileName: string
    companyName: string
    logo_url: string
    respondent: { name: string; title: string }
}

export interface SingleVideoManifest {
    url: string
    fileName: string
    companyName: string
    logo_url: string
    respondent: { name: string; title: string }
}

export const useSingleVideoManifest = (videoToken: string) => {
    const getSingleVideoManifest = async (videoToken: string): Promise<SingleVideoManifest> => {
        const url = `${storageDomain}/candidateAppData/${videoToken}/manifest.json`
        const res = await axios.get<rawSingleVideoManifest>(url)
        const data = res.data
        const manifest = { ...data, url: data.video }
        return manifest
    }

    const query = useQuery(
        ['singleVideoManifest', videoToken],
        () => getSingleVideoManifest(videoToken),
        { enabled: !!videoToken },
    )
    return query
}

interface RawPersonalizationManifest {
    name: string
    description: string
    senderInfo: {
        name: string
        title: string
    }
    intro_video: null | {
        fileName: string
        token: string
        audioOnly: boolean
        objectFit?: 'contain' | 'cover'
    }
}

export interface PersonalizationManifest {
    name: string
    description: string
    senderInfo: {
        name: string
        title: string
    }
    intro_video?: VideoDetails
}

export const getPersonalizationManifestFile = async (
    baseToken: string,
    personalizationToken: string,
): Promise<PersonalizationManifest> => {
    const url = `${storageDomain}/candidateAppData/${baseToken}/personalizations/${personalizationToken}/manifest.json`
    const res = await axios.get<RawPersonalizationManifest>(url)
    const pers: PersonalizationManifest = { ...res.data, intro_video: undefined }
    if (res.data?.intro_video !== null) {
        const orig = res.data.intro_video
        const mapped_intro_video = {
            topic: 'Introduction',
            coverText: '',
            labelLineOne: '',
            labelLineTwo: '',
            respondent: {},
            label: '',
            url: `${storageDomain}/candidateAppData/${personalizationToken}/${orig.fileName}`,
            ...orig,
        }
        pers['intro_video'] = mapped_intro_video
    }
    return pers
}

export type ManifestFile = {
    layout?: 'playlist' | 'page'
} & (VideoManifest | RawFeedManifest)
const getManifest = async (token: string, preview: boolean) => {
    const url = `${storageDomain}/candidateAppData/${token}/manifest.${
        preview ? 'preview.' : ''
    }json`
    const res = await axios.get<ManifestFile>(url)
    return res.data
}
export const useManifest = (token: string | undefined, preview: boolean) => {
    const qc = useQueryClient()
    return useQuery(
        ['manifest', token, preview],
        () => {
            const pdo = qc.getQueryData(['postDataOverride'])
            const curValue = qc.getQueryData<ManifestFile>(['manifest', token, preview])
            if (pdo && curValue) {
                return curValue
            }
            return token ? getManifest(token, preview) : undefined
        },
        {
            enabled: !!token,
        },
    )
}
const fetchManifest = (qc: QueryClient, token: string, preview: boolean) => {
    return qc.fetchQuery(['manifest', token, preview], () => getManifest(token, preview))
}

export const useManifestType = (token: string | undefined, preview: boolean) => {
    const manifest = useManifest(token, preview)

    const defaultLayout =
        token?.startsWith('RS') || token?.startsWith('RRRV')
            ? 'playlist'
            : token?.startsWith('FC')
            ? 'page'
            : undefined
    return { ...manifest, data: manifest?.data?.layout ?? defaultLayout ?? 'page' }
}

export const useSubscribedHostWindows = () => {
    const qc = useQueryClient()
    return useQuery<Window[]>(
        ['subscribedHostWindows'],
        async () => (await qc.getQueryData<Window[]>(['subscribedHostWindows'])) ?? [],
    )
}
export const useMaxHeight = () => {
    return useQuery<number | undefined | null>(['maxHeight'], () => null).data ?? undefined
}
export const usePostMessageOverride = () => {
    const queryClient = useQueryClient()
    useEffect(() => {
        window.parent.postMessage({ type: 'PING' }, '*')
        const listener = (m: MessageEvent) => {
            let payload: any = m.data
            if (![clientUrl1, clientUrl2].includes(m.origin) && payload.type !== 'PING') {
                console.debug('message ignored', m.origin, m)
                return
            }
            payload = typeof payload !== 'object' ? JSON.parse(m.data) : payload
            if (payload.type === 'PING') {
                queryClient.setQueryData<Window[]>(['subscribedHostWindows'], e => {
                    const n = e?.length ? e : []
                    const s = m.source
                    if (
                        !s ||
                        s instanceof MessagePort ||
                        s instanceof ServiceWorker ||
                        n.includes(s)
                    ) {
                        return
                    }
                    return n.concat(s)
                })
                queryClient.setQueryData<number | null>(
                    ['maxHeight'],
                    e => (payload.maxHeight ? Number(payload.maxHeight) : undefined) ?? e ?? null,
                ) // make sure we don't just throw any old nonsense through from this sender
                window.parent.postMessage(JSON.stringify({ type: 'PING_ACK', data: '' }), '*')
            } else if (payload.type === 'MANIFEST') {
                window.parent.postMessage(JSON.stringify({ type: 'DATA_ACK', data: '' }), '*')
                const data = payload.data
                const token = data.sampleToken

                queryClient.setQueryData(['postDataOverride'], true)
                queryClient.setQueryData(['roleSearchInfo', token], data.surveyData.roleSearchInfo)
                queryClient.setQueryData(['surveyDetails', token], data.surveyData)
                queryClient.setQueryData(['manifest', token, !!window.preview], data.videoManifest)
                queryClient.invalidateQueries(['videomanifest'])
                if (data.VideoManifest) {
                    ;[true, false, undefined].forEach(groupByContributor => {
                        queryClient.setQueryData(
                            ['videomanifest', token, !!window.preview, groupByContributor],
                            getTopicGroupsFromManifest(
                                data.VideoManifest,
                                token,
                                groupByContributor,
                            ),
                        )
                    })
                }
            }
        }
        window.addEventListener('message', listener)
        return () => {
            window.removeEventListener('message', listener)
        }
    }, [queryClient])
}

export const getFeedManifestFile = async (
    feedToken: string,
    preview?: boolean,
): Promise<FeedManifest> => {
    const url = `${storageDomain}/candidateAppData/${feedToken}/manifest.${
        preview ? 'preview.' : ''
    }json`
    const res = await axios.get<RawFeedManifest>(url)
    const cleanedVideoDetails: FeedVideoDetails[] = res.data.videos
        //@ts-ignore // technically it is possible for staff to add channels to the feed with the old builder.  we're moving types around a bit now, so im going to assume no client relied on that, and just quickly filter out any channels that might be in there.  This can be deleted once we move to only looking at page config
        .filter(v => v.type !== 'channel')
        .map((v, vidx) => ({
            url:
                `${storageDomain}/candidateAppData/${feedToken}/${v.fileName}` + (v.fragment ?? ''),

            posterUrl: v.posterFileName
                ? `${storageDomain}/candidateAppData/${feedToken}/${v.posterFileName}`
                : '',
            topic: v.topic,
            respondent: v?.respondent ?? {
                name: '',
                title: '',
            },
            fileName: v.fileName,
            respondentOtherChannels: v.respondentOtherChannels,
            prompt: v.prompt,
            tags: v.tags,
            type: v.type,
            duration: v.duration,
            audioOnly: !!v.audioOnly,
            objectFit: v.objectFit,
            key: `${vidx}`,
            coverText: v.coverText ?? '',
            labelLineOne: '',
            labelLineTwo: '',
        }))

    const pageConfig: FeedManifest['options']['page_config'] = {
        ...(res.data.options?.page_config ?? {}),
        ordered_sections: (res.data.options?.page_config?.ordered_sections ?? []).map(
            (s, sidx) => ({
                ...s,
                key: `section-${sidx}`,
                items: s.items.map((i, iidx) => {
                    if (i.type === 'video') {
                        return {
                            url:
                                `${storageDomain}/candidateAppData/${feedToken}/${i.fileName}` +
                                (i.fragment ?? ''),

                            key: `item-${sidx}-${iidx}`,
                            posterUrl: i.posterFileName
                                ? `${storageDomain}/candidateAppData/${feedToken}/${i.posterFileName}`
                                : '',
                            topic: i.topic,
                            respondent: i?.respondent ?? {
                                name: '',
                                title: '',
                            },
                            fileName: i.fileName,
                            respondentOtherChannels: i.respondentOtherChannels,
                            prompt: i.prompt,
                            tags: i.tags,
                            type: i.type,
                            duration: i.duration,
                            nameOverride:
                                typeof i.nameOverride === 'string'
                                    ? i.nameOverride
                                    : i.nameOverride?.coverText,
                            audioOnly: !!i.audioOnly,

                            // TODO: reconcile coverText with nameOverride.  coverText is what its called in channel builder and is probably the better name, but nameOverride is the old name for feeds
                            coverText: i.coverText ?? '',
                            labelLineOne: '',
                            labelLineTwo: '',

                            objectFit: i.objectFit,
                        }
                    } else {
                        return {
                            ...i,
                            key: `item-${sidx}-${iidx}`,
                            nameOverride:
                                typeof i.nameOverride === 'string'
                                    ? i.nameOverride
                                    : i.nameOverride?.coverText,
                            posterUrl: i.poster_url,
                        }
                    }
                }),
            }),
        ),
    }

    const options = {
        style: 'feed' as FeedStyle,
        allowedFilterCategories: ['topic', 'tag'] as FeedFilterOption[],
        allowSearch: false,
        ...(res.data.options ?? {}),
        page_config: pageConfig,
    }

    return {
        name: res.data.name ?? 'Meet Our Team',
        description: res.data.description,
        videos: cleanedVideoDetails,
        featuredLinks: res.data.featuredLinks,
        companyName: res.data.companyName,
        logo_url: res.data.logo_url || '',
        banner_url: res.data.banner_url || '',
        feedBackgroundColor: res.data.feedBackgroundColor || '',
        feedAccentColor: res.data.accentColor || '',
        v2: !!res.data.v2,
        v4: !!res.data.v4,
        options,
    }
}

export const isCollection = (
    item: FeedChannelDetails | VideoDetails,
): item is FeedChannelDetails => {
    return ['page', 'channel'].includes(item?.type ?? 'video')
}

export const isVideoDetails = (
    item: FeedChannelDetails | VideoDetails | undefined,
): item is VideoDetails => {
    return 'video' === (item?.type ?? 'video')
}

// export const getChildManifestsByToken = async (parentTopics: TopicGroup[], enabled: boolean) => {
//     const tokens: string[] = []
//     if (enabled) {
//         parentTopics.forEach(tl => {
//             tl.videos.forEach(v => {
//                 if (isCollection(v)) {
//                     const token = v.token
//                     if (!!token) {
//                         tokens.push(token)
//                     }
//                 }
//             })
//         })
//     }
//     const manifests = await Promise.all(tokens.map(t => getVideoManifestFile(t)))
//     const manByToken = Object.fromEntries(manifests.map((m, idx) => [tokens[idx], m]))
//     return manByToken
// }

export const isVideoManifest = (item: VideoManifest | RawFeedManifest): item is VideoManifest => {
    return !!(item as VideoManifest)?.topics
}
export const isRawFeedManifest = (item: any): item is RawFeedManifest => {
    const pManifest = item as Partial<RawFeedManifest>
    return (
        (!!pManifest?.options?.page_config?.ordered_sections?.length ||
            !!pManifest?.videos?.length) &&
        !!pManifest?.options
    )
}

export const hasTags = (item: any): item is { tags: string[] } => {
    return typeof item?.tags?.[0] === 'string'
}

export const isRawVideoDetails = (
    item: RawVideoDetails | RawFeedChannelDetails,
): item is RawVideoDetails => item.type === 'video'

const getTopicGroupsFromManifest = (
    manifest: ManifestFile,
    rolesearchToken: string,
    groupByContributor?: boolean,
    topicKey?: string,
) => {
    const topics: TopicGroup[] = (
        isVideoManifest(manifest)
            ? manifest?.topics ?? []
            : (manifest?.options?.page_config?.ordered_sections ?? []).map(rfs => ({
                  ...rfs,
                  topic: rfs.name,
                  videos: rfs.items,
              })) ?? []
    ).map((t: RawTopicGroup, idx) => {
        const mappedVideos: (VideoDetails | FeedChannelDetails)[] = t.videos.map(
            (oldItemDetails, iidx) => {
                if (oldItemDetails.type === 'page' || oldItemDetails.type === 'channel') {
                    return {
                        ...oldItemDetails,
                        posterUrl: oldItemDetails.poster_url,
                        nameOverride:
                            typeof oldItemDetails.nameOverride === 'string'
                                ? oldItemDetails.nameOverride
                                : oldItemDetails.nameOverride?.coverText,
                        key: `item-${idx}-${iidx}`,
                    }
                }
                const oldVideoDetails = { ...oldItemDetails } as RawVideoDetails
                return {
                    ...oldVideoDetails,
                    label: oldVideoDetails.label,
                    url: oldVideoDetails?.url
                        ? oldVideoDetails.url
                        : `${storageDomain}/candidateAppData/${rolesearchToken}/${oldVideoDetails.fileName}`,
                    topic: oldVideoDetails.topic,
                    respondent: oldVideoDetails?.respondent ?? {
                        name: '',
                        title: '',
                    },
                    fileName: oldVideoDetails.fileName,
                    audioOnly: !!oldVideoDetails.audioOnly,
                    objectFit: oldVideoDetails.objectFit,
                    coverText: oldVideoDetails.coverText ?? '',
                    labelLineOne:
                        oldVideoDetails.labelLineOne ?? oldVideoDetails?.respondent?.name ?? '',
                    labelLineTwo:
                        oldVideoDetails.labelLineTwo ?? oldVideoDetails?.respondent?.title ?? '',
                    posterUrl:
                        oldVideoDetails.posterUrl ??
                        (oldVideoDetails.posterFileName &&
                            `${storageDomain}/candidateAppData/${rolesearchToken}/${oldVideoDetails.posterFileName}`) ??
                        undefined,
                }
            },
        )
        if (t.introDuration === 0 && t.intro_video_url) {
            mappedVideos.unshift({
                url: t.intro_video_url,
                label: '',
                respondent: {},
                topic: '',
                fileName: '',
                audioOnly: false,
                coverText: '',
                labelLineOne: '',
                labelLineTwo: '',
            })
        }
        const newTopic = {
            ...t,
            videos: mappedVideos,
            key: t.key ?? `tg-${idx}-${t.topic.toLowerCase().replace(/[^\x61-\x7a]/g, '')}`,
        }
        return newTopic
    })
    if (groupByContributor) {
        const contributors: TopicGroup[] = []
        for (const tg of topics) {
            for (const v of tg.videos) {
                if (isCollection(v)) {
                    const collectionCollection = contributors.find(c => c.topic === 'Collections')
                    if (collectionCollection) {
                        collectionCollection.videos.push(v)
                        continue
                    } else {
                        contributors.push({
                            topic: 'Collections',
                            videos: [v],
                            key: 'collections',
                        })
                    }
                } else {
                    const topicName = v.respondent.title ?? v.respondent.name ?? 'Unknown'
                    const existingContributor = contributors.find(c => c.topic === topicName)
                    if (existingContributor) {
                        existingContributor.videos.push(v)
                        continue
                    }
                    contributors.push({
                        respondent: {
                            posterUrl: v.posterUrl,
                            videoUrl: v.url,
                            videoOptions: {
                                scale: v.scale,
                                translate: v.translate,
                            },
                            ...v.respondent,
                        },
                        // ...tg,
                        key: `${v.respondent.name}`,
                        topic: topicName,
                        videos: [
                            {
                                ...v,
                            },
                        ],
                    })
                }
            }
        }
        return topicKey ? contributors.filter(t => t.key === topicKey) : contributors
    }
    return topicKey ? topics.filter(t => t.key === topicKey) : topics
}
export const useVideoManifestFile = (rolesearchToken: string | undefined, preview?: boolean) => {
    const { data: manifest } = useManifest(rolesearchToken, !!preview)
    const tg = useMemo(() => {
        return manifest && rolesearchToken
            ? getTopicGroupsFromManifest(manifest, rolesearchToken)
            : undefined
    }, [manifest, rolesearchToken])
    return { ...manifest, data: tg }
}

export enum CTAActions {
    DEFAULT = 1, // our standard yes im interested
    HIDDEN = 2,
    CUSTOM_LINK = 3, // single button that opens an external link
}
export interface CTASettings {
    CTASetting?: CTAActions
    CTATargetUrl?: string
    CTAPositiveText?: string
}

export interface RoleSearchInfo {
    description: string
    responsibilities: string
    benefits: string[]
    company_name: string
    logo_url?: string
    dark_background_logo_url?: string
    lang?: string
    name: string
    roleType?: 'Full-time' | 'Part-time' | 'Contract'
    compensationType?: 'Salary' | 'Hourly' | 'Contract'
    compensationRange?: string[] // 2 elements, [low end, high end]
    jobPostingWidgetUrl?: string
    useExternalPostingAsDetailsPage?: boolean
    candidateAppDefaultCTA?: CTAActions
    candidateAppCustomCTATargetUrl?: string
    candidateAppCustomCTAPositiveText?: string
    candidateAppCTAPositiveText?: string
    removeWednesdayBranding?: boolean
    customWidgetCoverText?: string
    widgetMinimizedSize?: number
    widgetMedimizedSize?: number
    widgetPositionLeft?: number
    widgetPositionRight?: number
    widgetPositionBottom?: number
    hideChannelDescription?: boolean
    accentColor?: string
    pageColor?: string
    pageBackgroundGradient?: boolean
    banner_url?: string
    wv2?: boolean
    wv4?: boolean
    wv8?: boolean
    wv16?: 'playlist' | 'grid' | 'grid-playlist'
    groupByContributor?: boolean
    isReel?: boolean
    token?: string
    companyInfo?: {
        company_name?: string
        url?: string
        icon?: string
        description?: string
        employeeCount?: string
    }
    location?: string
    experienceLevel?: string
    compensation?: string
    featuredLinks?: { link: string; name: string }[]
    logoConfig?: {
        height?: CSSProperties['height']
        flexDirection?: CSSProperties['flexDirection']
        alignItems?: CSSProperties['alignItems']
        justifyContent?: CSSProperties['justifyItems']
        gap?: CSSProperties['gap']
    }
}

export interface DeactivationInfo {
    deactivated: boolean
    careersSiteSlugName: string
    careersSiteExists: boolean
}

interface _SurveyDetails {
    qualificationQuestions: SurveyQuestion[]
    notInterestedQuestions: _RawQuestion[]
    unIdentifiedQuestions: _RawQuestion[]
    deactivationInfo?: DeactivationInfo
    denseViewMode?: boolean
}
interface _RawQuestion {
    qtype: '1' | '2' | '3'
    qtext: string
    qopts: {
        choices: string[]
        name?: string
        lowLabel?: string
        highLabel?: string
        target?: number
        numTicks?: number
    }
    token: string
    optional?: boolean
}
export interface SurveyDetails {
    qualificationQuestions: SurveyQuestion[]
    notInterestedQuestions: SurveyQuestion[]
    unIdentifiedQuestions: UnIdentifiedQuestion[]
    deactivationInfo?: {
        deactivated: boolean
        careersSiteSlugName: string
        careersSiteExists: boolean
    }
    denseViewMode?: boolean
}
export type SurveyQuestion = MultipleChoiceQuestion | OpenResponseQuestion | SliderQuestion
export interface MultipleChoiceQuestion {
    qtext: string | React.ReactElement
    choices: string[]
    qtype: '1'
    token: string
    optional?: boolean
    name?: string
}
export interface OpenResponseQuestion {
    qtext: string | React.ReactElement
    qtype: '2'
    token: string
    optional?: boolean
}
export interface SliderQuestion {
    qtext: string | React.ReactElement
    qtype: '3'
    qopts: {
        lowLabel: string
        highLabel: string
        target: number
        numTicks: number
    }
    token: string
    optional?: boolean
}

export interface UnIdentifiedQuestion extends OpenResponseQuestion {
    name: string
}

export const useRoleSearchInfo = (roleSearchToken?: string) => {
    const { itemToken } = useParams()
    const qc = useQueryClient()
    const key = ['roleSearchInfo', roleSearchToken ?? itemToken]
    const _get = async (token: string) => {
        const pdo = qc.getQueryData(['postDataOverride'])
        const curValue = qc.getQueryData<RoleSearchInfo>(key)
        if (pdo && curValue) {
            return curValue
        }
        const url = `${storageDomain}/candidateAppData/${token}/surveyData.${
            window.preview ? 'preview.' : ''
        }json`

        const res = await axios.get<{ roleSearchInfo: RoleSearchInfo }>(url)
        const roleSearchInfo = res.data.roleSearchInfo
        return roleSearchInfo
    }

    const query = useQuery(
        key,
        async () => {
            const roleSearchInfo = await _get(roleSearchToken ?? itemToken ?? '')
            return {
                ...roleSearchInfo,
                groupByContributor: !!roleSearchInfo.groupByContributor,
            }
        },

        { enabled: !!(roleSearchToken || itemToken) },
    )

    return query
}

const DEFAULT_UNIDENTIFIED_QUESTION_NAME_ORDER = ['name', 'email', 'phone', 'profile']

const getSurveyDetailsFromManifest = (manifest: _SurveyDetails, rolesearchToken: string) => {
    let adjustedSurveyDetails: SurveyDetails = {
        qualificationQuestions: [],
        notInterestedQuestions: [],
        unIdentifiedQuestions: [],
    }
    // these adjustments are because candidate and client frontend have slightly different understandings of these types and im aiming for an LCD in the backend definition
    adjustedSurveyDetails.qualificationQuestions = manifest.qualificationQuestions
    adjustedSurveyDetails.notInterestedQuestions = manifest.notInterestedQuestions
        .filter(q => q.qtype !== '3')
        .map(q => ({
            qtext: q.qtext,
            qtype: q.qtype,
            choices: q.qopts.choices,
            token: q.token,
        })) as (MultipleChoiceQuestion | OpenResponseQuestion)[]
    adjustedSurveyDetails.notInterestedQuestions = adjustedSurveyDetails.notInterestedQuestions.map(
        q => ({
            ...q,
            qtext: (
                <>
                    {(q.qtext as string).split('\n').map((line, idx) => (
                        <span
                            key={q.token}
                            style={{ color: idx === 3 ? 'gray' : undefined }}
                        >
                            {line}
                            <br />
                        </span>
                    ))}
                </>
            ),
        }),
    )
    adjustedSurveyDetails.unIdentifiedQuestions = manifest.unIdentifiedQuestions.map((q, idx) => ({
        qtext: q.qtext,
        qtype: '2',
        token: q.token,
        choices: q.qopts.choices,
        optional: q?.optional,
        name: q.qopts?.name ?? DEFAULT_UNIDENTIFIED_QUESTION_NAME_ORDER[idx],
    }))
    adjustedSurveyDetails.deactivationInfo = manifest?.deactivationInfo
    adjustedSurveyDetails.denseViewMode = manifest?.denseViewMode
    return adjustedSurveyDetails
}

export const useSurveyDetails = (roleSearchToken: string) => {
    const qc = useQueryClient()
    const getSurveyDetails = async (rolesearchToken: string) => {
        const pdo = qc.getQueryData(['postDataOverride'])
        const curValue = qc.getQueryData<SurveyDetails>(['surveyDetails', roleSearchToken])
        if (pdo && curValue) {
            return curValue
        }
        const url = `${storageDomain}/candidateAppData/${rolesearchToken}/surveyData.${
            window.preview ? 'preview.' : ''
        }json`
        const res = await axios.get<_SurveyDetails>(url)
        const adjustedSurveyDetails = getSurveyDetailsFromManifest(res.data, rolesearchToken)
        return adjustedSurveyDetails
    }

    const query = useQuery(['surveyDetails', roleSearchToken], () =>
        getSurveyDetails(roleSearchToken),
    )

    return query
}

export const submitSurveyResults = async (
    rolesearchToken: string,
    candidateToken: string,
    results: SurveyResults,
    projectToken?: string,
) => {
    if (window.preview) {
        return
    }

    await axios.post(CABDomain + '/submit', {
        results: {
            referredBy: new URLSearchParams(window.location.search).get('referrer') || undefined,
            ...results, // may also have referredBy in it already
        },
        rolesearchToken: rolesearchToken,
        candidateToken: candidateToken,
        sessionIdentifier: window.sessionIdentifier,
        candidateSource: window.source,
        projectToken: projectToken ?? window.projectToken,
    })
}

export const getNewSessionId = () => {
    const array = new Uint32Array(4)
    window.crypto.getRandomValues(array)
    const str = Array.from(array.values())
        .map(v => v.toString(36))
        .join('')
    return 'wt-' + str
}

export const getNewSessionExpirationTime = () => {
    const now = new Date()
    const sessionExpirationTime = new Date()
    sessionExpirationTime.setTime(now.getTime() + 30 * 60 * 1000)
    return sessionExpirationTime
}

export const logEvent = async (msg: object) => {
    if (window?.sessionIdentifier?.includes('1qn5x9014vq4h11phms3w6nkm9i')) {
        // this seems to be the id some bot generates, by having a fake implementation of js RNG
        return
    }
    if (window?.disableLogging) {
        return
    }
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('preview')) {
        return
    }
    const now = new Date()
    if (window.sessionExpirationTime < now || !window.sessionIdentifier) {
        window.sessionIdentifier = getNewSessionId()
    }
    window.sessionExpirationTime = getNewSessionExpirationTime()

    const url = CABDomain + '/log_event'
    const data = {
        msg: msg,
        rolesearchToken: window.rolesearchToken,
        feedConfigToken: window.feedToken,
        singleVideoToken: window.videoToken,
        projectToken: window.projectToken,
        candidateToken: window.candidateToken,
        sessionIdentifier: window.sessionIdentifier,
        preview: window.preview,
        candidateSource: window.source,
        personalizationToken: window.personalizationToken,
    }

    if (navigator.sendBeacon) {
        navigator.sendBeacon(url, JSON.stringify(data))
    } else {
        await axios.post(url, data)
    }
}

export const submitRoleRepInterviewTime = async (
    roleRepToken: string,
    startTime: string, //isostring
    endTime: string, //isostring
) => {
    const res = await axios.post(CABDomain + '/submit_role_rep_interview_time', {
        roleRepToken,
        startTime,
        endTime,
        projectToken: window.projectToken,
    })
    return res
}

export const submitInterviewSlots = async (
    rolesearchToken: string,
    candidateToken: string,
    availableSlots: [string, string][],
) => {
    if (window.preview) {
        return
    }
    await axios.post(CABDomain + '/submit_interview_slots', {
        rolesearchToken,
        candidateToken,
        availableSlots,
    })
}

export type CandidateStatus = 'pending' | 'submitted' | 'unknown'

export const useCandidateStatus = (
    roleSearchToken: string,
    candidateToken: string,
    projectToken?: string,
) => {
    const checkCandidateStatus = async (
        rolesearchToken: string,
        candidateToken: string,
        projectToken?: string,
    ) => {
        const url = `${storageDomain}/candidateAppData/${rolesearchToken}/candidateTokens/${candidateToken}`
        let status: CandidateStatus = 'unknown'
        try {
            const res = await axios.get<CandidateStatus>(url)
            status = res.data.trim() as CandidateStatus
        } catch (error) {
            status = 'unknown'
        }
        if (projectToken && ['pending', 'unknown'].includes(status)) {
            try {
                const res = await axios.get<CandidateStatus>(
                    `${storageDomain}/candidateAppData/${projectToken}/candidateTokens/${candidateToken}`,
                )
                status = res.data.trim() as CandidateStatus
            } catch (error) {}
        }
        return status
    }

    const query = useQuery(
        ['candidateStatus', roleSearchToken, candidateToken, projectToken],
        () => checkCandidateStatus(roleSearchToken, candidateToken, projectToken),
        { enabled: !!roleSearchToken },
    )
    return query
}

export interface CareersSiteJob {
    name: string
    job_id: string
    external_url: string
    wednesday_token?: string
    service: string
    description: string
    location: string
    department: string
    team: string
    info: { hideOnCareersSite?: boolean }
}
export interface CompanyBranding {
    accentColor: string
    feedBackgroundColor?: string
    careersSiteIntroText?: string
    careersSiteTitle?: string
    additionalDefaultSources?: { name: string; slug: string }[]
}
export interface CustomDomainData {
    companySlug?: string
    faviconUrl?: string
}
export const useDomainInfo = () => {
    return useQuery(['originInfo', window.location.host], async () => {
        const url = `${storageDomain}/customDomain/${window.location.host}.json`
        const res = await axios.get<CustomDomainData>(url, {
            validateStatus: s => s <= 404, // we expect 404 as a pretty common case; don't retry as if it's an error
        })
        if (res.data.faviconUrl) {
            const link: HTMLLinkElement =
                document.querySelector("link[rel*='icon']") || document.createElement('link')
            link.rel = 'icon'
            link.href = res.data.faviconUrl
            document.getElementsByTagName('head')[0].appendChild(link)
        }
        return res.data
    })
}
interface CareersPageData {
    jobs: CareersSiteJob[]
    companyName: string
    branding?: CompanyBranding
    logo_url?: string
    dark_background_logo_url?: string
}

export const getCareersPageData = async (companyName: string) => {
    const url = `${storageDomain}/careersSiteData/${companyName}/jobData.json`
    const res = await axios.get<CareersPageData>(url)
    return res.data
}

export const useCareersPageData = (companyName: string) => {
    const [results, setResults] = useState<CareersPageData>()
    useEffect(() => {
        ;(async () => {
            const result = await getCareersPageData(companyName)

            if (result.branding?.careersSiteTitle) {
                const title: HTMLTitleElement =
                    document.querySelector('title') || document.createElement('title')
                title.text = result.branding.careersSiteTitle
                document.getElementsByTagName('head')[0].appendChild(title)
            }
            setResults(result)
        })()
    }, [companyName])
    return results
}

const isProbablyAnExternalLink = (url: string) => {
    let parsedUrl = { protocol: '' }
    try {
        parsedUrl = new URL(url)
    } catch (e) {
        // pass - this will happen if the link is missing a protocol entirely (for example if its just /someInternalPage!)
    }
    const looksLikeARealProtocol =
        parsedUrl?.protocol === 'http:' || parsedUrl?.protocol === 'https:'
    return looksLikeARealProtocol
}

export const useLogAndNavigate = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    return (newPage: string, label: string, event_category: string) => {
        logEvent({ label: label, id: label, event_category: event_category })
        if (searchParams.get('iframeParentLocation')) {
            // console.log('sending the message up!', label)
            const iframe = document.createElement('iframe')
            iframe.style.display = 'none'
            iframe.src = `${searchParams.get('iframeParentLocation')}?wtcom=collapse`
            document.body.appendChild(iframe)
        }
        if (isProbablyAnExternalLink(newPage)) {
            window.location.assign(newPage)
        } else {
            navigate({ pathname: newPage, search: window.location.search })
        }
    }
}

export const getColorByBgColor = (bgColor: string) => {
    if (!bgColor) {
        return ''
    }
    bgColor = bgColor.replace('#', '')
    const r = parseInt(bgColor.slice(0, 2), 16)
    const g = parseInt(bgColor.slice(2, 4), 16)
    const b = parseInt(bgColor.slice(4, 6), 16)
    //     return r + g + b >= (255 * 3) / 3 ? '#000000' : '#ffffff'
    //https://stackoverflow.com/a/946734
    // who knows what the threshold should be?? at least 128, some say 186; ~150 seems to work well for the colors we've come across
    // #01ff95 ought to give black
    const threshold = 150
    return r * 0.299 + g * 0.587 + b * 0.114 > threshold ? '#000000' : '#ffffff'
}
export const useVideoManifests = (
    tokens: (string | undefined)[],
    groupByContributor?: boolean,
    topicKey?: string,
) => {
    const qc = useQueryClient()
    const queries = useQueries({
        queries: tokens.map(t => ({
            queryKey: ['videomanifest', t, !!window.preview, groupByContributor],
            queryFn: async () =>
                t
                    ? getTopicGroupsFromManifest(
                          await fetchManifest(qc, t, !!window.preview),
                          t,
                          groupByContributor,
                          topicKey,
                      )
                    : undefined,
            enabled: !!t,
        })),
    })
    return queries
}
const CUR_PATH_QK = (token: string, groupByContributor?: boolean, topicKey?: string) => [
    token,
    'wv4CurVideoPath',
    groupByContributor,
    topicKey,
]
export type VideoPath = { idx: number; topicKey: string | null; rsToken?: string }[]

function mod(n: number, m: number) {
    return ((n % m) + m) % m
}

export const useMutedLoop = () => {
    const qc = useQueryClient()
    const mutedLoop = useQuery(['wv8', 'MutedLoop'], () => true).data
    return useMemo(
        () =>
            [mutedLoop, (v: boolean) => qc.setQueryData(['wv8', 'MutedLoop'], v)] as [
                boolean | undefined,
                (a: boolean) => void,
            ],
        [mutedLoop, qc],
    )
}
const PREVIEW_RATES = [1, 2, 3]
export const usePreviewPlaybackRate = () => {
    const qc = useQueryClient()
    const rate = useQuery(['wv8', 'PreviewPlaybackRate'], () => 1).data
    return useMemo(
        () =>
            [
                rate,
                (v?: number) => {
                    if (v) {
                        qc.setQueryData(['wv8', 'PreviewPlaybackRate'], v)
                        return v
                    }
                    const cur = qc.getQueryData<number>(['wv8', 'PreviewPlaybackRate'])
                    const curIdx = PREVIEW_RATES.findIndex(v => v === cur)
                    const newVal = PREVIEW_RATES[(curIdx + 1) % PREVIEW_RATES.length]
                    qc.setQueryData<number | undefined>(['wv8', 'PreviewPlaybackRate'], newVal)
                    return newVal
                },
            ] as const,
        [rate, qc],
    )
}

export const useSelectedFilters = () => {
    const { itemToken } = useParams()
    const qc = useQueryClient()
    const queryKey = useMemo(() => ['selectedFilters', itemToken], [itemToken])
    const cur = useQuery<string[]>({
        queryKey,
        initialData: [],
    }).data
    return useMemo(
        () => [cur, (v: string[]) => qc.setQueryData(queryKey, v)] as const,
        [cur, qc, queryKey],
    )
}

export const useMute = (def: boolean = true) => {
    const qc = useQueryClient()
    const mutedLoop = useQuery(['wv8', 'Mute'], () => def).data
    return useMemo(
        () =>
            [mutedLoop, (v: boolean) => qc.setQueryData(['wv8', 'Mute'], v)] as [
                boolean | undefined,
                (a: boolean) => void,
            ],
        [mutedLoop, qc],
    )
}
export const useAutoplay = () => {
    const qc = useQueryClient()
    const mutedLoop = useQuery(['wv8', 'Autoplay'], () => false).data
    return useMemo(
        () =>
            [mutedLoop, (v: boolean) => qc.setQueryData(['wv8', 'Autoplay'], v)] as [
                boolean | undefined,
                (a: boolean) => void,
            ],
        [mutedLoop, qc],
    )
}
export const useCurVideoPath = (
    groupByContributor?: boolean,
    topicKey?: string,
    _token?: string,
) => {
    const qc = useQueryClient()
    const { itemToken } = useParams()
    const token = _token ?? itemToken ?? window.rolesearchToken ?? window.feedToken
    const { data: baseManifest } = useVideoManifests([token], groupByContributor, topicKey)[0]
    if (!window.rolesearchToken && itemToken) {
        window.rolesearchToken = itemToken
    }

    const { data: curVideoPath } = useQuery<unknown, unknown, VideoPath>(
        CUR_PATH_QK(token, groupByContributor),
        () => [{ idx: 0, topicKey: baseManifest?.[0]?.key, rsToken: token }],
        { enabled: !!baseManifest, staleTime: Infinity },
    )
    const manifests = useVideoManifests(
        curVideoPath?.map(v => v.rsToken) ?? [token],
        groupByContributor,
        topicKey,
    )

    const setCurVideo = useCallback(
        async (n: VideoPath[number], append?: boolean) => {
            const rsToken = n.rsToken ?? curVideoPath?.slice(-1)[0].rsToken ?? token
            if (!rsToken) {
                console.warn('norstoken')
                return
            }
            const manifest = await qc.fetchQuery(
                ['videomanifest', rsToken, !!window.preview, groupByContributor],
                async () =>
                    getTopicGroupsFromManifest(
                        await fetchManifest(qc, rsToken, !!window.preview),
                        rsToken,
                        groupByContributor,
                    ),
            )
            const topic =
                n.topicKey === null ? manifest[0] : manifest.find(t => t.key === n.topicKey)
            if (!topic) {
                console.warn('no topic', rsToken, n.topicKey, manifest)
                return
            }
            const item = topic.videos[n.idx]
            qc.setQueryData<VideoPath | undefined>(
                CUR_PATH_QK(token, groupByContributor),
                o => [
                    ...(o?.slice(0, append ? undefined : -1) ?? []),
                    { ...n, topicKey: topic.key, rsToken },
                ],
                {},
            )
            if (isCollection(item)) {
                setCurVideo({ rsToken: item.token, topicKey: null, idx: 0 }, true)
            }
        },
        [curVideoPath, qc, groupByContributor, baseManifest],
    )
    const popCurVideo = useCallback(
        (levels = 1, cycle = true) => {
            qc.setQueryData<VideoPath>(CUR_PATH_QK(token, groupByContributor), oldPath => {
                if (!oldPath) {
                    return []
                }
                const targetManifest = manifests.slice(0 - levels - 1)[0]
                const oldLastVid = oldPath.slice(0 - levels - 1)[0]
                const oldLastTopicIdx = targetManifest.data?.findIndex(
                    t => t.key === oldLastVid.topicKey,
                )
                const targetManifestVids =
                    targetManifest.data?.flatMap(t =>
                        t.videos.map((v, vidx) => ({ ...v, topicKey: t.key, idx: vidx })),
                    ) ?? []
                const targetVidFlatIdx =
                    targetManifestVids.findIndex(
                        v => v.topicKey === oldLastVid.topicKey && v.idx === oldLastVid.idx,
                    ) + 1
                let targetVid = targetManifestVids[targetVidFlatIdx % targetManifestVids.length]
                if (
                    targetVidFlatIdx >= targetManifestVids.length &&
                    !cycle &&
                    (curVideoPath?.length ?? 0) - levels > 1
                ) {
                    popCurVideo(levels + 1, cycle)
                    return undefined
                }
                const ret = [...oldPath?.slice(0, 0 - levels - 1), { ...oldLastVid, ...targetVid }]
                return ret
            })
            const newVid = qc.getQueryData<VideoPath>(CUR_PATH_QK(token, groupByContributor))
            if (newVid) {
                setCurVideo(newVid.slice(-1)[0]) // to trigger the other checks (if it has to dive deeper)
            }
        },
        [curVideoPath?.length, groupByContributor, manifests, qc, setCurVideo, baseManifest],
    )
    const _curVideo = curVideoPath?.slice(-1)[0]
    const curTopicKey = _curVideo?.topicKey
    const topicList = useMemo(() => manifests.slice(-1)[0]?.data ?? [], [manifests])
    const currentTopic = topicList?.find(t => t.key === curTopicKey)
    const curVideo = useMemo(
        () =>
            _curVideo ? { ..._curVideo, details: currentTopic?.videos[_curVideo.idx] } : undefined,
        [_curVideo, currentTopic?.videos],
    )
    const nextVideo = useCallback(
        (reverse?: boolean) => {
            if (!currentTopic || !curVideo || !topicList) {
                console.warn('I"m missing something')
                return
            }
            const flatVids =
                topicList.flatMap(t =>
                    t.videos.map((v, vidx) => ({ ...v, topicKey: t.key, idx: vidx })),
                ) ?? []
            const targetFlatIdx =
                flatVids.findIndex(
                    t => t.topicKey === curVideo.topicKey && t.idx === curVideo.idx,
                ) + (reverse ? -1 : 1)
            const targetVid = flatVids[mod(targetFlatIdx, flatVids.length)]
            if (targetFlatIdx < flatVids.length || (curVideoPath?.length ?? 0) <= 1) {
                setCurVideo({ ...curVideo, ...targetVid })
            } else {
                popCurVideo(1, false)
            }
            // const i = curVideo?.idx ?? 0
            // const n = currentTopic?.videos[i + 1]
            // if (!n) {
            //     const tidx = topicList?.findIndex(t => t.key === curVideo.topicKey)
            //     const nextTopic = topicList[tidx + 1]
            //     if (nextTopic && nextTopic.videos.length) {
            //         setCurVideo({
            //             ...curVideo,
            //             idx: 0,
            //             topicKey: nextTopic.key,
            //         })
            //     } else {
            //         popCurVideo()
            //     }
            // } else if (isCollection(n)) {
            //     setCurVideo({
            //         ...curVideo,
            //         idx: i + 1,
            //     })
            //     // setCurVideo({
            //     //     rsToken: n.token,
            //     //     idx: 0,
            //     //     topicKey: null,
            //     // }, true)
            // } else {
            //     setCurVideo({ ...curVideo, idx: i + 1 })
            // }
        },
        [
            curVideo,
            curVideoPath?.length,
            currentTopic,
            popCurVideo,
            setCurVideo,
            topicList,
            baseManifest,
        ],
    )
    // }, [curVideo, currentTopic, popCurVideo, setCurVideo, topicList])
    const roleSearchInfos = useRolesearchInfos(curVideoPath?.map(v => v.rsToken) ?? []).map(
        rsi => rsi.data,
    )
    const breadCrumbs = useMemo(() => roleSearchInfos.map(rsi => rsi?.name), [roleSearchInfos])
    const flatVids =
        topicList.flatMap(t => t.videos.map((v, vidx) => ({ ...v, topicKey: t.key, idx: vidx }))) ??
        []
    const curCatIdx =
        curVideo &&
        flatVids.findIndex(t => t.topicKey === curVideo.topicKey && t.idx === curVideo.idx)
    const isLastVideo = curCatIdx && curCatIdx >= flatVids.length - 1
    return useMemo(() => {
        return {
            curVideoPath,
            setCurVideo,
            currentTopic,
            topicList,
            curVideo,
            nextVideo,
            breadCrumbs,
            popCurVideo,
            isLastVideo,
        }
    }, [
        breadCrumbs,
        curVideo,
        curVideoPath,
        currentTopic,
        isLastVideo,
        nextVideo,
        popCurVideo,
        setCurVideo,
        topicList,
        baseManifest,
    ])
}

const useRolesearchInfos = (tokens: (string | undefined)[]) => {
    const getRoleSearchInfo = async (rolesearchToken: string) => {
        const url = `${storageDomain}/candidateAppData/${rolesearchToken}/surveyData.${
            window.preview ? 'preview.' : ''
        }json`
        const res = await axios.get<{ roleSearchInfo: RoleSearchInfo }>(url)
        return {
            ...res.data.roleSearchInfo,
            token: res.data.roleSearchInfo.token || rolesearchToken,
        }
    }
    return useQueries({
        queries: tokens.map(t => ({
            queryKey: ['roleSearchInfo', t],
            queryFn: () => (t ? getRoleSearchInfo(t) : null),
        })),
    })
}

export const useColors = (roleSearchToken?: string) => {
    const { itemToken } = useParams()
    const { data: roleSearchInfo } = useRoleSearchInfo(roleSearchToken ?? itemToken)
    return useMemo(() => {
        const accentColor = roleSearchInfo?.accentColor ?? '#00BD4D'
        const pageColor = roleSearchInfo?.pageColor ?? '#FFFFFF'

        return {
            accentColor: accentColor,
            accentColorContrast: getColorByBgColor(accentColor),
            pageColor: pageColor,
            pageColorContrast: getColorByBgColor(pageColor),
        }
    }, [roleSearchInfo?.accentColor, roleSearchInfo?.pageColor])
}

export const useLogoConfig = (roleSearchToken?: string) => {
    const { itemToken } = useParams()
    const { data: roleSearchInfo } = useRoleSearchInfo(roleSearchToken ?? itemToken)
    return useMemo(() => {
        const {
            height = 24,
            flexDirection = 'column',
            alignItems,
            justifyContent,
            gap = 4,
        } = roleSearchInfo?.logoConfig ?? {}

        return {
            height: height,
            flexDirection,
            alignItems: alignItems ?? (flexDirection.includes('column') ? undefined : 'center'),
            justifyContent,
            gap,
        }
    }, [roleSearchInfo?.logoConfig])
}
export const getChannelParams = (item: FeedChannelDetails | VideoDetails) => {
    if (isVideoDetails(item)) {
        return new URLSearchParams({})
    }
    const itemUrl = new URL(item.url)
    const itemSearch = new URLSearchParams(itemUrl.search) // pages have wv4 baked in
    const params: Record<string, string> = {}
    for (let [k, v] of itemSearch.entries()) {
        params[k] = v
    }
    return new URLSearchParams({
        ...params,
        iframeParentLocation: encodeURIComponent(window.location.href),
        wtc: btoa(JSON.stringify({ s: 'channel', CTASetting: CTAActions.HIDDEN })),
        wv4: '1',
        customCoverText: ' ',
        posterUrl: '', // what exactly is poster file name?
        hideFlip: '1',
        hidePlay: '1',
        disablePreview: '1',
        disableBlur: '1',
    })
}
export const WIDGET_STATES = ['expanded', 'minimized', 'medimized'] as const
export type WIDGET_STATE = (typeof WIDGET_STATES)[number]

export const useWidgetMessenger = (
    roleSearchInfo: RoleSearchInfo | undefined,
    version?: 16 | 8,
) => {
    const subscribedHostWindows = useSubscribedHostWindows().data
    const [widgetState, setWidgetState] = useState<undefined | WIDGET_STATE>()
    useEffect(() => {
        const shareSize = () => {
            subscribedHostWindows?.forEach(w => {
                w.postMessage(
                    JSON.stringify({
                        type: 'RESIZE',
                        version,
                        height: 330,
                        medimizedWidgetSize: roleSearchInfo?.widgetMedimizedSize ?? 330,
                        minimizedWidgetSize: roleSearchInfo?.widgetMinimizedSize ?? 80,
                        positionLeft: roleSearchInfo?.widgetPositionLeft,
                        positionRight: roleSearchInfo?.widgetPositionRight,
                        positionBottom: roleSearchInfo?.widgetPositionBottom,
                        coverText: roleSearchInfo?.customWidgetCoverText,
                    }),
                    '*',
                ) // we already checked that we trust them when adding them to subscribed list
            })
        }
        shareSize()
        const removeListeners = subscribedHostWindows?.map(w => {
            const listener = (e: MessageEvent) => {
                if (e.source !== w) {
                    return
                }
                if (e.data.type === 'STATE') {
                    const state = e.data.state
                    if (WIDGET_STATES.includes(state)) {
                        setWidgetState(state)
                    }
                }
            }
            window.addEventListener('message', listener)
            return () => window.removeEventListener('message', listener)
        })
        return () => removeListeners?.forEach(f => f?.())
    }, [
        roleSearchInfo?.customWidgetCoverText,
        roleSearchInfo?.widgetMedimizedSize,
        roleSearchInfo?.widgetMinimizedSize,
        roleSearchInfo?.widgetPositionBottom,
        roleSearchInfo?.widgetPositionLeft,
        roleSearchInfo?.widgetPositionRight,
        subscribedHostWindows,
        version,
    ])
    return { widgetState, subscribedHostWindows }
}
