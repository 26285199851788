import React, { lazy, Suspense } from 'react'
import { LandingPageProps } from './LandingPage'
const LazyLandingPage = lazy(() => import('./LandingPage'))

const LandingPage = (props: LandingPageProps) => (
    <Suspense fallback={null}>
        <LazyLandingPage {...props} />
    </Suspense>
)

export default LandingPage
