import React from 'react'
import { createGlobalStyle } from 'styled-components'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

//  The overflow hidden on body children is messing with mantine's popovers (they add a div at the body level, without a clear way to override styles on without a clunky mess of grabbing it with js)
const CustomStyles = createGlobalStyle`
    html,
    body {
        overflow-x: hidden;
    }

    body > * {
        // overflow-x: hidden;
        ${tw`antialiased`}
    }
    
    body > #root {
        overflow-x: hidden;
    }
`

export const GlobalStyles = () => (
    <>
        <BaseStyles />
        <CustomStyles />
    </>
)
