import React, { lazy, Suspense } from 'react'
import { FeedIntroProps } from './FeedIntro'
const LazyFeedIntro = lazy(() => import('./FeedIntro'))

const FeedIntro = (props: FeedIntroProps) => (
    <Suspense fallback={null}>
        <LazyFeedIntro {...props} />
    </Suspense>
)

export default FeedIntro
