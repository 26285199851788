import React, { lazy, Suspense } from 'react'

const LazyCareersPage = lazy(() => import('./CareersPage'))

const CareersPage = (props: {}) => (
    <Suspense fallback={null}>
        <LazyCareersPage {...props} />
    </Suspense>
)

export default CareersPage
